import { useApolloClient } from '@apollo/client/react/hooks';
import { type CustomerQuery } from '../../../__generated__/graphql-client-types';
import { buildGTMCustomerLoginEvent } from '../../../helpers/analytics/gtm/event-builders';
import { updateJWT } from '../../../helpers/general-helper/general-helper';
import { CUSTOMER } from '../../../queries/customer/customer.queries';
import { type AuthenticatedCustomer } from '../../../types/authentication.types';
import { useTrackEvent } from '../../analytics/analytics.hooks';

/**
 * updates customer data post auth
 *
 * @deprecated - this hook is exported for testing purposes it should not be
 * used outside of authentication.hooks.ts
 */
export const useUpdateCustomer = () => {
	const trackEvent = useTrackEvent();
	const client = useApolloClient();

	return async (authedCustomer: AuthenticatedCustomer, isLoggedIn = true, isNewAccount = false, updatedByEmployee = false) => {
		// update jwt token
		updateJWT(authedCustomer.token);
		// update isLoggedIn on client-side renders
		window.dataLayer.isLoggedIn = isLoggedIn;
		// clear apollo cache, refetch queries
		await client.resetStore();

		// track successful logins
		if (isLoggedIn && updatedByEmployee === false) {
			const customerData = client.readQuery<CustomerQuery>({
				query: CUSTOMER
			});

			if (customerData) {
				const {
					customer: {
						id: userDBID,
						profile: { type: customerType }
					}
				} = customerData;
				trackEvent(buildGTMCustomerLoginEvent(customerType, isNewAccount, parseInt(userDBID, 10)));
			}
		}
	};
};

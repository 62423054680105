import gql from 'graphql-tag';
import { ErrorFields } from '../common-fields.queries';

export const REQUEST_PASSWORD_RESET = gql`
	mutation RequestPasswordReset($email: String!) {
		requestPasswordReset(email: $email) {
			success
		}
	}
`;

export const CREATE_ACCOUNT = gql`
	mutation CreateAccount($input: CreateAccountInput!) {
		createAccount(input: $input) {
			... on AuthenticatedCustomer {
				token
			}
			... on Error {
				...ErrorFields
			}
		}
	}
	${ErrorFields}
`;

export const LOGIN = gql`
	mutation Login($userName: String!, $password: String!, $token: String) {
		login(userName: $userName, password: $password, token: $token) {
			... on AuthenticatedCustomer {
				token
			}
			... on AuthenticationFailure {
				message
			}
		}
	}
`;

export const LOGIN_AS_CUSTOMER = gql`
	mutation LoginAsCustomer($customerId: Int!) {
		loginAsCustomer(customerId: $customerId) {
			... on AuthenticatedCustomer {
				token
			}
		}
	}
`;

export const LOGOUT = gql`
	mutation Logout {
		logout
	}
`;

export const RESET_PASSWORD = gql`
	mutation ResetPassword($newPassword: String!, $hash: String!) {
		resetPassword(newPassword: $newPassword, hash: $hash) {
			... on AuthenticatedCustomer {
				token
			}
			... on Error {
				...ErrorFields
			}
		}
	}
	${ErrorFields}
`;

export const CHECK_PASSWORD_RESET_HASH = gql`
	query CheckPasswordResetHash($hash: String!) {
		checkPasswordResetHash(hash: $hash) {
			success
		}
	}
`;
